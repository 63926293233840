import React from 'react'
import './customButton.css'

export const CustomButton = ({onclick = ()=>{}, text, customClass, disabled = false, tooltip='', children}) => {

  return (
    <button className={`${customClass || "customButton"} tooltip`} onClick={onclick} disabled={disabled}>
        {text}
        {children}
        {tooltip && <span className="tooltiptext">{tooltip}</span>}
    </button>

  )
}

