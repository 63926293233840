import React from 'react'

function WalletNotConnected() {
  return (
    <div>
    <img className="walletImg" src={require("../../assets/img/wallet.png")} alt="wallet"/>
    <p>
      Connect your wallet to use
      <br />
      The Alien Staking
    </p>
  </div>
  )
}

export default WalletNotConnected