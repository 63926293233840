import React from 'react'
import fockIMG from "../../assets/img/fockToken.png"
import "./fockPrice.css"

function FockPrice({price, textSize = 15}) {
  return (
    <div className='fockPriceWrapper'>
        <img height={textSize * 2} src={fockIMG}/>
        <span style={{fontSize: textSize}}>{price}</span>
    </div>
  )
}

export default FockPrice