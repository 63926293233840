import React, { useEffect, useState, useContext } from "react";
import CustomBox from "../../../../components/customBox/CustomBox";
import ufoIMG from "../../../../assets/img/ufo.gif";
import "./mission.css";
import { StakingContext } from './../../../../helpers/context/Context';
import { convertTZ } from "../../../../helpers/convertTimezone/convertTZ";

function Mission({MissionCount}) {

  const {UserData} = useContext(StakingContext)

  const [progressbarValue, setprogressbarValue] = useState(0);
  const [LastStaked, setLastStaked] = useState(new Date());
  useEffect(() => {
    if (!UserData.stakedAt) {
      setprogressbarValue(0);
      return;
    }

    let StakedSplited = UserData.stakedAt.split("-");

    let staked =convertTZ (new Date(
      parseInt(StakedSplited[0]),
      parseInt(StakedSplited[1]) - 1,
      parseInt(StakedSplited[2])
    ), "Africa/Abidjan"); //real staked at

    let progress = ((30 - UserData.nextClaim) * 100) / 30;

    if (progress == 100)
      progress = 102;

    setprogressbarValue(progress);
    setLastStaked(staked);
  }, [UserData.stakedAt, UserData.nextClaim]);

  

  return (
    <CustomBox lines={true}>
      <div className="missionWrapper">
        <div className="missionDetail">
          <div>MISSION</div>
          <div>{UserData.stakedAt ? MissionCount : 0}</div>
        </div>
        <img src={ufoIMG} />
        <div className="missionProgress">
          <div>STARTED</div>
          {UserData.stakedAt
            ? `${
                LastStaked.getMonth() + 1
              }/${LastStaked.getDate()}/${LastStaked.getFullYear()  - 2000}`
            : "--/--/--"}
          <div className="progressBar">
            <div style={{ width: progressbarValue + "%" }}></div>
          </div>
        </div>
      </div>
    </CustomBox>
  );
}

export default Mission;
