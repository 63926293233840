import React, {useState, useEffect} from "react";
import spinnerGIF from "../../assets/img/coin.gif";
import { createPortal } from "react-dom";
import "./spinner.css";

function Spinner({ isShowing }) {

  const [isShowingControl, setisShowingControl] = useState(false);
  const [ActivatedDate, setActivatedDate] = useState(new Date())

  useEffect(() => {
    if(!isShowingControl && !isShowing)
      return;
      
    if(isShowing){
      setisShowingControl(true);
      setActivatedDate(new Date());
      return;
    }
    let timePassed =  (new Date() - ActivatedDate);
    if( timePassed < 1000){ //to let the spinner activated at least 1 second to not look like a glitch
      let timeout = 1000 - (timePassed);
      setTimeout(() => {
        setisShowingControl(false)
      }, timeout);
    }else{
      setisShowingControl(false)
    }

  }, [isShowing])
  
  return isShowingControl && (
    createPortal(
      <div className="spinnerWrapper">
        <img src={spinnerGIF} alt="loading" />
      </div>,
      document.getElementById("spinner")
    )
  );
}

export default Spinner;
