import React, { useState, useContext, useEffect }  from 'react'
import './navbar.css'
import logoIMG from '../../assets/img/logo.png';
import ConnectWallet from './components/connectWallet/ConnectWallet';
import FockPrice from './../../components/fockPrice/FockPrice';
import { StakingContext } from './../../helpers/context/Context';
import { ParseIntWithDots } from './../../helpers/parseIntWithDots/ParseIntWithDots';

export const Navbar = () => {

  const {UserData} = useContext(StakingContext);

  return (
    <>
        <div className='navbar'>
            <img src={logoIMG} className="logo"/>
          <FockPrice price={ParseIntWithDots(UserData.fock)} textSize="16" />
          <div>
            <ConnectWallet/>
          </div>

        </div>
        <p className='navbarBottom'></p>
    </>
      )
}
