import React, {useContext} from 'react'
import coinIMG from "../../../../../assets/img/coin.gif"
import CustomBox from '../../../../../components/customBox/CustomBox';
import FockPrice from '../../../../../components/fockPrice/FockPrice';
import { StakingContext } from '../../../../../helpers/context/Context';
import { ParseIntWithDots } from '../../../../../helpers/parseIntWithDots/ParseIntWithDots';
function FockData() {

  const {UserData} = useContext(StakingContext);

  
  return (
      <CustomBox>
        <div  className="statBlock">
            <img src={coinIMG}/>
            <div>
              <div>
                <div className='statBlockTitle'>EXPECTED<br/>FOCK</div>
                <FockPrice price={ParseIntWithDots(UserData.stakedAt ? UserData.expected : 0)} textSize={15}/>
              </div>
              <div>
                <div className='statBlockTitle' style={{marginTop: 30}}>CLAIMABLE</div>
                <FockPrice price={ParseIntWithDots(UserData.claimable)} textSize={15}/>
              </div>
            </div>
        </div>
      </CustomBox>
  )
}

export default FockData