import React, {useContext} from 'react'
import DottedData from '../../../../../components/dottedData/DottedData'
import alienIMG from "../../../../../assets/img/alienUpsideDown.gif"
import CustomBox from '../../../../../components/customBox/CustomBox'
import { StakingContext } from './../../../../../helpers/context/Context';

function StakedData({AlienCounts}) {

  const {UserData} = useContext(StakingContext);
  
  return (
      <CustomBox>
        <div className="statBlock">
                <img src={alienIMG}/>
                <div>
                    <div className='statBlockTitle'>{!UserData.stakedAt ? "TO STAKE" : "STAKED"}</div>
                    <div  style={{marginTop: 10}}>
                      <DottedData title="TAB" data={AlienCounts.boy}/>
                      <DottedData title="TAG" data={AlienCounts.girl}/>
                      <DottedData title="TAD" data={AlienCounts.doogle}/>
                      <DottedData title="TAU" data={AlienCounts.ufo}/>
                      <div  style={{marginTop: 15}}>
                        <DottedData title="TOTAL" data={AlienCounts.boy + AlienCounts.girl + AlienCounts.ufo + AlienCounts.doogle  }/>
                      </div>

                    </div>
                </div>
        </div>
      </CustomBox>
  )
}

export default StakedData