import React, { useState, useEffect } from "react";
import "./App.css";
import { Navbar } from "./containers/navbar/Navbar";
import { Home } from "./containers/home/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { StakingContext } from "./helpers/context/Context";
import CustomModal from "./components/customModal/CustomModal";
import Spinner from "./components/spinner/Spinner";
import { Footer } from './containers/footer/Footer';

function App() {

  const [IsPageEnabled, setIsPageEnabled] = useState(true);
  const [WalletId, setWalletId] = useState("");
  const [IsSpinnerShowing, setIsSpinnerShowing] = useState(false);
  const [UserData, setUserData] = useState({claimable: 0, expected: 0, stakedAt: null, fock: 0, speedingTickets: 0, nextClaim: 0})
  
  const [ErrorMessage, setErrorMessage] = useState("");
  const [ErrorModalShowing, setErrorModalShowing] = useState(false)

  useEffect(() => {
    if(ErrorMessage)
      setErrorModalShowing(true);
  }, [ErrorMessage])

  useEffect(() => {
    if(!ErrorModalShowing)
    setErrorMessage("");
  }, [ErrorModalShowing])
  

  // useEffect(()=>{
  //   setWalletId("0x00329074dFE4eC0822634cA1C52B89DACDBFB7Fb")

  // },[])
  return (
    <StakingContext.Provider
      value={{
        IsPageEnabled,
        setIsPageEnabled,
        WalletId,
        setWalletId,
        setIsSpinnerShowing,
        UserData,
        setUserData,
        setErrorMessage
      }}
    >
      <BrowserRouter>
        <Spinner isShowing={IsSpinnerShowing} />
        <CustomModal message={ErrorMessage} okButton={true} ShowModal={ErrorModalShowing} setShowModal={setErrorModalShowing}/>
        <Navbar />
        <div className="applicationContent">
            <Routes>
              <Route path="*" element={<Home />} />
            </Routes>
        </div>
        <CustomModal />
        <Footer/>
      </BrowserRouter>
    </StakingContext.Provider>
  );
}

export default App;
