import React, {useState, useContext} from 'react'
import { CustomButton } from '../../../../../components/customButton/CustomButton';
import { StakingService, UntakingService } from '../../../../../services/staking/StakingService';
import { StakingContext } from '../../../../../helpers/context/Context';
import SignatureHelper from '../../../../../helpers/signatureHelper/SignatureHelper';
import CustomModal from '../../../../../components/customModal/CustomModal';
import { ParseIntWithDots } from '../../../../../helpers/parseIntWithDots/ParseIntWithDots';

function StakingButton({MissionCount}) {
    const { WalletId, setIsSpinnerShowing, setUserData, UserData, setErrorMessage} = useContext(StakingContext);

    const [ShowModal, setShowModal] = useState(false);
    const [ModalMessage, setModalMessage] = useState("")
    const [ModalFooter, setModalFooter] = useState("");

    const StakingHandler = async () => {
      
      if (!window.ethereum || !WalletId)
      return;
      
      try{
        setIsSpinnerShowing(true);
          const [msg, signature] = await SignatureHelper(WalletId)

          if(!UserData.stakedAt){

            let res = await StakingService( signature, WalletId, msg)

            setUserData( prevUserData =>({
              ...prevUserData,
              ...{claimable: 0,
                expected: res.expected,
                stakedAt: res.staked_at,
                nextClaim: res.next_claim}
              })
            );

            setErrorMessage(res.msg);

            console.log(res);
          }else{
            let res = await UntakingService( signature, WalletId, msg)

            setUserData( prevUserData =>({
              ...prevUserData,
              ...{claimable:0, stakedAt: null, nextClaim: 0}
              })
            );
            console.log(res);
            
            setErrorMessage(res.msg);

          }
        setShowModal(false)
          setIsSpinnerShowing(false);

        }catch(er){
          console.log(er)
          setErrorMessage(er.msg);
          setShowModal(false);
          setIsSpinnerShowing(false);
        }
      }

    const StakingButtonHandler = () => {
      console.log(UserData);
      if(MissionCount == 0) return;
      if (!window.ethereum || !WalletId)
      return;
      setShowModal(true)
      setModalMessage(`ARE YOU SURE YOU WANT TO ${UserData.stakedAt ? "UNSTAKE" : "STAKE"} THE MISSION?`)
      setModalFooter(UserData.stakedAt ?"" : `EST ${ParseIntWithDots(UserData.expected)} $FOCK/mo`)
     
    }
  return (
    <>
      <CustomModal message={ModalMessage} rejectButton={true}
      ShowModal={ShowModal} setShowModal={setShowModal}
      footer={ModalFooter}
      >
        <CustomButton text="CONFIRM" onclick={StakingHandler} />
      </CustomModal>
      <CustomButton text={UserData.stakedAt ? "UNSTAKE" : "STAKE"} onclick={StakingButtonHandler} disabled={MissionCount == 0}/>
    </>
    )
}

export default StakingButton