import { getConfig, URI } from "../../helpers/netWorking/Constants";
import { customFetch } from "../../helpers/netWorking/CustomFetch";

export const UserService = async (WalletID) => {

  try {
        var body = {
            walletId: WalletID,
        }

        var configs = getConfig("POST", body);
    
        const response = await customFetch(`${URI}/user`, configs);
console.log(response)
        return response;
      } catch (e) {
        throw e;
      }
}
